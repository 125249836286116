import React from 'react'
import styles from './index.module.scss'
import TikTok from '@/assets/TikTok.webp'
import Instagram from '@/assets/Instagram.webp'
import Pinterest from '@/assets/Pinterest.webp'
import Youtube from '@/assets/Youtube.webp'
import Logo from '@/assets/couponLogo.webp'
import dynamic from "next/dynamic";
import Image from "next/image";

const ListType13 = dynamic(()=>import('@/components/List/type13'))

export default function Footer({ footer }: any) {
  return (
    <div className={styles.footer}>
      <div className={styles.footer_container}>
        <div className={styles.logo}>
          <Image src={Logo.src} unoptimized alt={"logo"} width={100} height={100}/>

          <div className={styles.link_logo}>
            <a href="https://www.tiktok.com/@timelycoupons" target="_sale">
              <Image src={TikTok.src} unoptimized alt={"TikTok"} width={20} height={20}/>
            </a>
            <a href="https://www.pinterest.com/timelycoupons/" target="_sale">
              <Image src={Pinterest.src} unoptimized alt={"Pinterest"} width={20} height={20}/>
            </a>
            <a href="https://www.youtube.com/@TimelyCoupons" target="_sale">
              <Image src={Youtube.src} unoptimized alt={"Youtube"} width={20} height={20}/>
            </a>
            <a href="https://www.instagram.com/timelycoupons/" target="_sale">
              <Image src={Instagram.src} unoptimized alt={"Instagram"} width={20} height={20}/>
            </a>
          </div>
        </div>
        {footer && footer.about && (
          <ListType13
            name={footer.about.name}
            links={footer.about.links}
          ></ListType13>
        )}
      </div>
      <hr style={{ borderColor: '#fff', borderBottom: '0' }} />
      {footer && footer.copyright && (
        <div className={styles.footer_bottom}>{footer.copyright ?? ''}</div>
      )}
    </div>
  )
}
